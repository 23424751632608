const testimonials = [
    {
        "body": "CRM Dost has revolutionized our TeleCalling process. The user-friendly interface and robust features make managing leads a breeze.",
        "author": {
            "name": "John M.",
            "handle": "johnm",
            "imageUrl": "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
    },
    {
        "body": "The ability to track calls and view client information during calls has significantly improved our efficiency. CRM Dost is a must-have for any call center.",
        "author": {
            "name": "Emily R.",
            "handle": "emilyr",
            "imageUrl": "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
    },
    {
        "body": "As a business owner, I love the owner dashboard. It provides clear insights into our team's performance, helping us make informed decisions.",
        "author": {
            "name": "Rajesh K.",
            "handle": "rajeshk",
            "imageUrl": "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
        }
    },
    {
        "body": "CRM Dost has streamlined our lead management, and the team loves the TeleCaller of the month certificate feature. It really boosts morale!",
        "author": {
            "name": "Sophia L.",
            "handle": "sophial",
            "imageUrl": "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
    },
    {
        "body": "We've seen a noticeable improvement in productivity since implementing CRM Dost. The app is reliable and ensures our data is secure.",
        "author": {
            "name": "David P.",
            "handle": "davidp",
            "imageUrl": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
    },
    {
        "body": "The comprehensive features of CRM Dost have made it an essential tool for our TeleCalling team. Highly recommended for any business looking to enhance their sales process.",
        "author": {
            "name": "Anita S.",
            "handle": "anitas",
            "imageUrl": "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        }
    }
]

export default function Testimonials() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="font-bold tracking-tight text-gray-900 text-lg sm:text-2xl">
                        We have worked with thousands of amazing people, and CRM Dost has revolutionized TeleCalling and Lead Management for them
                    </h2>
                </div>
                <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
                    <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                        {testimonials.map((testimonial) => (
                            <div key={testimonial.author.handle} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                                    <blockquote className="text-gray-900">
                                        <p>{`“${testimonial.body}”`}</p>
                                    </blockquote>
                                    <figcaption className="mt-6 flex items-center gap-x-4">
                                        <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                                        <div>
                                            <div className="font-semibold text-gray-900">{testimonial.author.name}</div>
                                            <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
