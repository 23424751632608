export const isSalePro = false;
export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}


export function scrollToSection(id, px = 0) {
    if (px) {
        window.scrollTo({ top: px, behavior: 'smooth' });
        return
    }
    const element = document.getElementById(id);
    if (element) {
        // Get the top position of the element relative to the document
        const topOffset = element.getBoundingClientRect().top + window.pageYOffset;

        // Smooth scroll to the top offset of the element
        window.scrollTo({ top: topOffset, behavior: 'smooth' });
    } else {
        console.warn(`Element with ID ${id} not found.`);
    }
}