import Footer from '../components/footer';
import Header from '../components/header';


export default () => {

    return <>
        <Header />
        <div className="p-10 px-6 sm:px-0 m-auto max-w-7xl">
            <h1 className='text-2xl mb-5'>Privacy Policy</h1>
            <p>Effective Date: 1st Jan 2024</p>
            <h2>1. Introduction</h2>
            <p>
                This Privacy Policy outlines how CRM Dost ("we," "us," or "our")
                collects, uses, and protects your personal and business information
                when you use our CRM solution and associated services. By using CRM
                Dost, you agree to the practices described in this policy. If you do
                not agree with this Privacy Policy, please do not use our services.
            </p>
            <h2>2. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li>
                    Personal Information: This may include your name, email address,
                    phone number, and other contact information you provide.
                </li>
                <li>
                    Business Information: This includes data related to your business,
                    such as company name, size, industry, and business-specific data
                    you input into our CRM solution.
                </li>
                <li>
                    Usage Information: We may collect data related to how you use CRM
                    Dost, including log files, device information, and analytics data.
                </li>
            </ul>
            <h2>3. How We Use Your Information</h2>
            <p>We use the collected information for the following purposes:</p>
            <ul>
                <li>
                    Provide and Improve Services: To deliver our CRM services, enhance
                    user experience, and develop new features.
                </li>
                <li>
                    Communications: To send you service updates, alerts, and marketing
                    information. You can opt out of marketing communications at any
                    time.
                </li>
                <li>
                    Customer Support: To provide customer support and address user
                    inquiries and issues.
                </li>
                <li>
                    Legal Compliance: To comply with applicable laws, regulations, and
                    legal processes.
                </li>
            </ul>
            <h2>4. Data Security</h2>
            <p>
                We take data security seriously and employ industry-standard
                measures to protect your information. However, no method of
                transmission over the internet is completely secure. We cannot
                guarantee absolute data security, and you use our services at your
                own risk.
            </p>
            <h2>5. Sharing Your Information</h2>
            <p>
                We do not share your personal information with third parties except
                in the following circumstances:
            </p>
            <ul>
                <li>
                    With Your Consent: We may share information when you explicitly
                    consent to the sharing.
                </li>
                <li>
                    Service Providers: We may engage third-party service providers to
                    assist us in delivering and maintaining our services.
                </li>
                <li>
                    Legal Obligations: We may disclose information when required by
                    law, legal processes, or to protect our rights or the rights of
                    others.
                    <h2>6. Your Choices</h2>
                    <p>You have the following choices regarding your data:</p>
                    <ul>
                        <li>
                            Access and Correction: You may access, correct, or update your
                            personal information through your account settings.
                        </li>
                        <li>
                            Deletion: You can request the deletion of your account and
                            associated data. Please note that some information may be
                            retained for legal or operational reasons.
                        </li>
                    </ul>
                    <h2>7. Changes to this Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. We will
                        notify you of significant changes through email or by posting a
                        notice on our website. Please review the updated policy
                        regularly.
                    </p>
                    <h2>8. Contact Information</h2>
                    <p>
                        If you have any questions or concerns regarding this Privacy
                        Policy, please contact us at info@crmdost.com.
                    </p>
                    <p>Last Updated: 24th Oct 2023</p>
                </li>
            </ul>
        </div>
        <Footer />
    </>
}