import http, { AxiosResponse } from 'axios';
import { Apis, baseURL } from './config';
import handleError from './handle-error';
import { isSalePro } from '..';
import { strings } from '../strings';
export * from './config';

class CrmDostAPI {
    static async baseHeaders() {
        return {
            locale: 'in',
            isSalePro,
        };
    }

    static get({
        route,
        headers,
        params,
        timeout,
        errorFunction
    }) {
        return this.api('get', route, headers, params, errorFunction, timeout);
    }

    static post({
        route,
        headers,
        params,
        timeout,
        errorFunction
    }) {
        return this.api('post', route, headers, params, errorFunction, timeout);
    }

    static patch({
        route,
        headers,
        params,
        timeout,
        errorFunction
    }) {
        return this.api('patch', route, headers, params, errorFunction, timeout);
    }

    static delete({
        route,
        headers,
        params,
        timeout,
        errorFunction
    }) {
        return this.api('delete', route, headers, params, errorFunction, timeout);
    }

    static async api(requestType, route, headers, params, errorFunction = null, timeout = 60000) {
        const url = `${baseURL}${route}`;
        const baseHeaders = await CrmDostAPI.baseHeaders();

        const requestConfig = {
            headers: headers ? { ...baseHeaders, ...headers } : baseHeaders,
            params: {}
        };

        http.create();
        http.defaults.timeout = timeout;
        if (requestType === 'get' || requestType === 'delete') {
            return http[requestType](url, requestConfig)
                .then((response) => {
                    return checkValidJSON(response);
                })
                .catch((error) => {
                    handleError(error, errorFunction)
                    if (error && (error.message.toLowerCase().includes('timeout of') || error.message.toLowerCase().includes('network error'))) {
                        error.message = strings.apiError.internet;
                    }
                    return Promise.reject(error);
                });
        }

        return http[requestType](url, params, requestConfig)
            .then((response) => {
                return checkValidJSON(response);
            })
            .catch((error) => {
                console.log('error for post', url);
                handleError(error, errorFunction)
                return Promise.reject(error);
            });
    }
}

export default CrmDostAPI;

function checkValidJSON(response) {
    if (response.data !== 'string') return response;
    return Promise.reject(response);
}


