import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import CrmDostAPI, { apis } from '../utils/api'
import { ThemeLoader } from './theme-loader'


export default function ContactForm({ btn, title, tagName }) {
    const [loading, setLoading] = useState(false)
    const [phone, setPhone] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isValid, setIsValid] = useState(false);

    const validatePhoneNumber = (phoneNumber) => {
        const isValidPhone = isValidPhoneNumber('+' + phoneNumber?.toString());
        setIsValid(isValidPhone);
        return isValidPhone;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formFields = {};

        formData.forEach((value, key) => {
            formFields[key] = value;
        });

        if (validatePhoneNumber(phone)) {
            // valid phone

            setLoading(true)
            CrmDostAPI.post({
                route: apis.CONTACT,
                params: {
                    "customer_email": formData.get('email'),
                    "customer_phone": `+${phone}`,
                    "assign_user_email": "info@salepro.in",
                    "customer_name": `${formData.get('firstName') || ''} ${formData.get('lastName') || ''}`,
                    "source": "Contact Form",
                    "message": `This user submitted a contact form on crmdost.com, ${title || ''}`,
                    isCrmDost: true
                }
            }).then((success) => {
                if (success?.data?.statusCode === 201) {
                    setIsOpen(false);
                    document.getElementById('contact-form')?.reset?.()
                    setPhone('');
                    toast(`Your details have been submitted. We'll connect with you within 24 hours.`, { type: 'success' })
                }
            }).catch((error) => {
                console.error("Error:", error)
            }).finally(() => setLoading(false))
        } else {
            toast('Please enter a valid phone number.', { type: 'error' })
        }
    };

    const Tag = tagName || 'span'

    return (
        <>
            <Tag className='cursor-pointer' onClick={() => setIsOpen(true)}>{btn}</Tag>
            <Transition appear show={isOpen}>
                <Dialog as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black bg-opacity-70">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform scale-95"
                                enterTo="opacity-100 transform scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform scale-100"
                                leaveTo="opacity-0 transform scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl rounded-xl bg-white p-6 backdrop-blur-2xl">
                                    {loading ? <ThemeLoader /> : null}
                                    <Dialog.Title as="h3" className="text-base font-medium text-black">
                                        <div className="mx-auto max-w-2xl text-center">
                                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title || 'Contact Us'}</h2>
                                            <p className="mt-2 mb-3 sm:px-10 text-lg leading-8 text-gray-600">
                                                Please provide your details so that we can assist you and resolve your query.
                                            </p>
                                        </div>
                                    </Dialog.Title>

                                    <div className="isolate bg-white px-6 py-2 lg:px-8">
                                        <button
                                            className="absolute -right-1 -top-1 sm:-right-5 sm:-top-5 cursor-pointer z-100 bg-blue-500 text-white rounded-full p-3 shadow-lg focus:outline-none shadow-xl"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            <XMarkIcon className='h-10 w-10' />
                                        </button>
                                        <div
                                            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                                                style={{
                                                    clipPath:
                                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                                }}
                                            />
                                        </div>

                                        <form id="contact-form" onSubmit={onSubmit} name='contact-form' className="mx-auto mt-2 max-w-2xl">
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                                <div>
                                                    <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        First Name <span className='text-red-500'>*</span>
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            required
                                                            placeholder='Enter your first name'
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            autoComplete="given-name"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Last Name
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            placeholder='Enter your last name'
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            autoComplete="family-name"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Company
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            placeholder='Enter your company name'
                                                            type="text"
                                                            name="company"
                                                            id="company"
                                                            autoComplete="organization"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Email <span className='text-red-500'>*</span>
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            placeholder='Enter your email'
                                                            required
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="email"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Phone Number <span className='text-red-500'>*</span>
                                                    </label>
                                                    <div className="relative mt-2.5">

                                                        <PhoneInput
                                                            placeholder='Enter your phone number'
                                                            required
                                                            country={'in'}
                                                            // showDropdown
                                                            specialLabel=''
                                                            enableSearch
                                                            containerClass='lead-phone'
                                                            inputClass='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                                            value={phone || ''}
                                                            onChange={p => { setPhone(p); validatePhoneNumber(p) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Message <span className='text-red-500'>*</span>
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <textarea
                                                            placeholder='Enter your message'
                                                            required
                                                            name="message"
                                                            id="message"
                                                            rows={4}
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            defaultValue={''}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-10">
                                                <input
                                                    className="cursor-pointer block w-full rounded-md bg-yellow-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    value={title || "Let's Talk"}
                                                    type='submit'
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
