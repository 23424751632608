import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Footer from '../components/footer';
import Plans from '../components/plans';
import Section from '../components/section';
import Testimonials from '../components/testimonials';
import '../styles/custom.scss';
import '../styles/font.scss';
import '../styles/index.scss';
import Header from '../components/header';
import { useEffect } from 'react';
import { scrollToSection } from '../utils';
import ContactForm from '../components/contact-form';

function Home() {

  useEffect(() => {
    setTimeout(() => scrollToSection(window.location.href.split('#')?.[1]), 100)
  }, [])

  return (
    <>
      <ParallaxProvider>
        <Header />
        <div className="App">
          <Section id="top" space={80} bg={'bg-amber-50'} bgImage={"section-1"}>
            <Parallax speed={-10} className="grow-1 md:flex">
              <div>
                <h1 className="max-w-2xl text-2xl tracking-tight text-gray-700 lg:col-span-2 xl:col-auto text-left mb-4 mt-6 poppins-semibold">
                  CRMDost: The Ultimate Solution for Efficient Lead Management
                </h1>
                <p className="text-lg leading-8 text-gray-500 text-left poppins-medium">
                  Streamline your lead management process and supercharge your sales efforts with CRMDost, a comprehensive CRM tool designed to optimize your operations.
                </p>
                <div className="mt-10 md:flex items-center gap-x-6">
                  <a
                    href="https://admin.crmdost.com"
                    className="rounded-lg bg-black px-10 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    Start Free
                  </a>

                  <span onClick={() => scrollToSection(undefined, 100)}>
                    <ContactForm
                      btn={
                        <a className="mt-8 md:mt-0 block text-md font-semibold leading-6 text-sky-500">
                          Contact Us <span aria-hidden="true">→</span>
                        </a>
                      }
                    />
                  </span>


                </div>
              </div>
            </Parallax>
            <Parallax speed={20} className="grow-1 flex items-center justify-center relative mt-10 md:mt-0">
              <img
                src="./images/mac.png"
                alt="MacBook"
                className="laptop-image"
              />
            </Parallax>
          </Section>

          <Section id="app" bg={"bg-blue-50"} bgImage={"section-2"}>
            <Parallax speed={10} className="md:flex z-10" style={{ flex: 0.8 }}>
              <div className="relative flex items-center justify-center">
                <img className="mobile-image w-1/3 absolute" src="./images/mobile.png" alt="Mobile" />
                <img className="ipad-image" src="./images/ipad.png" alt="iPad" />
              </div>
            </Parallax>
            <Parallax speed={-10} className="mt-56 md:mt-0 grow-1 md:ml-10" style={{ flex: 1.2 }}>
              <h1 className="max-w-2xl text-2xl tracking-tight text-gray-700 lg:col-span-2 xl:col-auto text-left mb-4 mt-6 poppins-semibold">
                CRM Dost App
              </h1>
              <ul className="text-left my-4 list-disc list-inside">
                <li>Are you losing sales because of employee mistakes?</li>
                <li>Missing follow-up calls?</li>
              </ul>
              <p className="text-sm leading-8 text-gray-600 text-left poppins-medium pb-32 sm:pb-0">
                CRM Dost is a highly effective application for TeleCallers. In today's competitive market, ensuring your TeleCalling team has the best tools is crucial. CRM Dost provides an exceptional management app for TeleCallers, contributing significantly to business growth. A great TeleCaller app must offer daily formats tailored to requirements, and CRM Dost does just that with its comprehensive features.
              </p>
              <p className="mt-4 text-sm leading-8 text-gray-600 text-left poppins-medium pb-32 sm:pb-0">
                This app is invaluable for both sales and service calls, specifically designed for call centers. It facilitates rapid call-making and is instrumental in lead generation. CRM Dost benefits both employees and business owners, offering a simple, reliable, and lightweight solution that takes minimal space on your device. Client information is accessible during calls, enhancing efficiency.
              </p>
            </Parallax>
          </Section>

          <Section id="keyFeatures" bg={"bg-gray-50"} bgImage={"section-3"}>
            <div className="pt-5 sm:pt-0">
              <div className="text-center">
                <h1 className="max-w-2xl text-2xl tracking-tight text-gray-700 lg:col-span-2 xl:col-auto text-left mb-4 mt-6 poppins-semibold">
                  Key Features:
                </h1>
                <p className="text-lg leading-8 text-gray-500 text-left poppins-medium">
                  CRM Dost app offers a lot of powerful features that make it more amazing. Here, we have listed the top features of this app:
                </p>
              </div>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 mt-12 pb-10">
                <div className="bg-blue-400 p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl rounded-xl">
                  <img className="mb-5" src="./images/improve-business.png" alt="Improve Business" />
                  <div className="text-white">Improve 325% Business by CRM Dost</div>
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 43.png" alt="Save Time" />
                  Save 3X time
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 41.png" alt="Rocket Speed Dial" />
                  Dial numbers at rocket speed
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 45.png" alt="Set Follow-up Reminders" />
                  Set the follow-up reminders
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 21.png" alt="No Excel Sheet Needed" />
                  Need not Maintain the Excel Sheet
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 47.png" alt="Track Follow-up Activities" />
                  Track Follow-up Activities Date-Wise
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 42.png" alt="Send Message" />
                  Send Message at a Single Click
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 19.png" alt="Never Miss Any Lead" />
                  Never Miss Any Lead
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 16.png" alt="Upload Data" />
                  Upload Data to TeleCaller
                </div>
                <div className="p-10 text-xl poppins-semibold text-gray-600 flex flex-col items-center justify-center shadow-2xl bg-white rounded-xl">
                  <img className="mb-5" src="./images/Layer 15.png" alt="Call Schedule Feature" />
                  Call Schedule Feature
                </div>
              </div>
            </div>
          </Section>

          <Section id={"benefits"} bg={'bg-sky-50'} innerClass={"pb-8"} space={25} bgImage={'section-4'}>
            <div className='md:flex lg:mx-24 bg-sky-400 items-center justify-center p-3 md:p-10 rounded-xl shadow-xl'>
              <Parallax speed={-10} className='grow-1 md:flex'>
                <div>
                  <h1 className="max-w-2xl text-2xl text-white 
                    lg:col-span-2 xl:col-auto text-left mb-4 mt-6 poppins-semibold">
                    Owner Benefits
                  </h1>

                  <ul className='text-left list-disc list-inside text-md mr-6 text-white poppins-medium'>
                    <li className='flex items-center'> <img src='./images/Layer 44.png' className='mr-4' /> Improve your team performance with the CRM Dost app.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> You can also track your TeleCalling activities with this app.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> It will help you focus on customer relationships and revenue.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> The owner can track the leads' close opportunities.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> You can also boost the productivity of your sales team.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> You can download the Telecaller of the month certificate to motivate your team.</li>
                    <li className='flex items-center'><img src='./images/Layer 44.png' className='mr-4' /> You can also check the owner dashboard where you can see all results on a daily, weekly, and monthly basis.</li>
                  </ul>

                  <div className="mt-10 md:flex items-center gap-x-6">
                    <a
                      href="https://admin.crmdost.com"
                      className="block rounded-lg bg-black px-10 py-2.5 text-sm 
                            font-semibold text-white shadow-md hover:bg-gray-500 
                            focus-visible:outline focus-visible:outline-2 
                            focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      Start Free
                    </a>
                    <ContactForm
                      title="Schedule Demo"
                      btn={
                        <a
                          className="mt-6 md:mt-0 block rounded-lg bg-red-500 px-10 py-2.5 text-sm 
                            font-semibold text-white shadow-md hover:bg-red-600 
                            focus-visible:outline focus-visible:outline-2 
                            focus-visible:outline-offset-2 focus-visible:outline-red-400"
                        >
                          Schedule a free demo
                        </a>
                      }
                    />

                  </div>
                </div>
              </Parallax>
              <Parallax speed={20} className='grow-1 relative'>
                <img
                  src="./images/mac.png"
                  alt=""
                  className="desktop-image"
                />
                <img className='ipad-image-history absolute' src='./images/ipad-v.png' />
              </Parallax>
            </div>
          </Section>



          <Section bg={"bg-gray-50"} bgImage={"section-5"}>
            <Testimonials />
          </Section>

          <Section id="plans" bg={"bg-gray-50"} bgImage={"section-6"}>
            <Plans />
          </Section>

          <Footer />
        </div>
      </ParallaxProvider>
      {/* <OpenAIChat /> */}
    </>
  );
}

export default Home;
