import { toast } from "react-toastify"

export default (error, errorFunction = null) => {
    const msg = error?.response?.data?.message

    if (msg) {
        if (msg.includes("'phone'") && msg.includes("ER_DUP_ENTRY: Duplicate entry")) {
            const mobile = msg.split("'+")?.[1]?.split('-')?.[0];
            if (mobile) {
                // return toast(`+${mobile} - Mobile number already exists. You cannot add a duplicate mobile number.`, { type: 'error' })
            }
        }

    }
    if (errorFunction) {
        // return toast(`${errorFunction(error?.response?.data?.message)}`, { type: 'error' })
    }
    // return toast(`${error?.response?.data?.message || 'Request failed'}`, { type: 'error' })
}