import Footer from '../components/footer';
import Header from '../components/header';

export default () => {

    return <>
        <Header />
        <div className="p-10 px-6 sm:px-0 m-auto max-w-7xl">
            <h1 className='text-2xl mb-5'>Refund Policy</h1>
            <p>We appreciate your business with CRMDost. Please read this refund policy carefully.</p>

            <h2>Refund Eligibility</h2>
            <p>We offer refunds on a case-by-case basis. Refund requests will be considered under the following circumstances:</p>
            <ul>
                <li>Technical Issues: If you encounter technical issues that prevent you from using our service and our support team is unable to resolve the issue within a reasonable time frame.</li>
                <li>Service Disruption: In the event of a prolonged service disruption or outage that significantly impacts your ability to use CRMDost.</li>
                <li>Dissatisfaction: If you are dissatisfied with the quality of our service or features, please contact us with your concerns.</li>
            </ul>

            <h2>Refund Procedure</h2>
            <p>To request a refund, please contact our support team at info@crmdost.com. Provide detailed information about the reason for your refund request, along with any relevant documentation or evidence.</p>
            <p>Refunds will be processed within 7 days upon approval. Please note that refunds may take additional time to appear in your account depending on your payment method and financial institution.</p>

            <h2>Non-Refundable Items</h2>
            <p>Some items are non-refundable:</p>
            <ul>
                <li>Subscription Fees: Subscription fees paid for CRMDost services are generally non-refundable, except under the circumstances outlined above.</li>
                <li>Customization Services: Fees paid for customization or integration services are non-refundable once the service has been performed.</li>
            </ul>

            <h2>Contact Us</h2>
            <p>If you have any questions about our refund policy or need assistance with a refund request, please contact us at info@crmdost.com. Our support team is here to assist you.</p>

            <p>This refund policy was last updated on 1st Jan 2024.</p>
        </div>
        <Footer />
    </>
}