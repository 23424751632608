import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import CrmDostAPI, { apis } from '../utils/api'
import { classNames } from '../utils'
import { ThemeLoader } from './theme-loader'

export default function Plans() {
    const [loading, setLoading] = useState(false)
    const [frequency, setFrequency] = useState({})
    const [frequencies, setFrequencies] = useState([])
    const [tiers, setTiers] = useState([])
    const [currency, setCurrency] = useState('₹')

    useEffect(() => {
        // setLoading(true)
        CrmDostAPI.get({
            route: apis.PLANS
        }).then((success) => {
            setFrequency(success?.data?.data?.frequencies[0])
            setFrequencies(success?.data?.data?.frequencies)
            setTiers(success?.data?.data?.tiers)
            setCurrency(success?.data?.data?.currency || '₹')
        }).catch((error) => {
            const data = {
                frequencies: [
                    { value: 'monthly', label: 'Monthly', priceSuffix: '/user/month' },
                    { value: 'yearly', label: 'Yearly', priceSuffix: '/user/year' },
                ],
                currency: '₹',
                tiers: [
                    {
                        "planType": 1,
                        "actualPlan": { "monthly": 'plan_OQIhbansPvYRvM', "yearly": 'plan_OQIk2xwq59f3tG' },
                        "name": "Startup",
                        "id": "tier-startup",
                        "href": "",
                        "price": { "monthly": 499, "yearly": 4999 },
                        "description": "The essentials to deliver your best work for clients.",
                        "features": [
                            "Up to 10 users",
                            "Up to 100,000 customers",
                            "Basic analytics",
                            "72-hour support response time",
                            "Email Notifications",
                            "Push Notifications"
                        ],
                        "mostPopular": false
                    },
                    {
                        "planType": 2,
                        "actualPlan": { "monthly": 'plan_OQIiV6ZiV2uqj9', "yearly": 'plan_OQIkOnxbbtGYim' },
                        "name": "Value for Money",
                        "id": "tier-best",
                        "href": "#",
                        "price": { "monthly": 999, "yearly": 9999 },
                        "description": "A plan designed to scale with your rapidly growing business.",
                        "features": [
                            "Up to 25 users",
                            "Up to 1,000,000 customers",
                            "Advanced analytics",
                            "Email Notifications",
                            "Push Notifications",
                            "<b>24-hour support response time</b>",
                            "<b>Marketing automations</b>",
                            "<b>WhatsApp Notifications</b>"
                        ],
                        "mostPopular": true
                    },
                    {
                        "planType": 3,
                        "actualPlan": { "monthly": 'plan_OQIjWoMCVXjy57', "yearly": 'plan_OQIlOkAd1pEQlL' },
                        "name": "Enterprise",
                        "id": "tier-enterprise",
                        "href": "#",
                        "price": { "monthly": 1999, "yearly": 19999 },
                        "description": "Dedicated support and infrastructure tailored for your company.",
                        "features": [
                            "<b>Unlimited users</b>",
                            "<b>Unlimited customers</b>",
                            "<b>Advanced analytics</b>",
                            "Email Notifications",
                            "WhatsApp Notifications",
                            "Marketing automations",
                            "<b>1-hour dedicated support response time</b>",
                            "<b>Custom reporting tools</b>"
                        ],
                        "mostPopular": false
                    },
                ]
            }
            setFrequency(data?.frequencies[0])
            setFrequencies(data?.frequencies)
            setTiers(data?.tiers)
            setCurrency(data?.currency || '₹')
        }).finally(() => setLoading(false))
    }, [])


    if (loading)
        return <ThemeLoader />

    return (
        <>
            <div className="bg-sky-500/4">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    {/* <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-sky-600">Pricing</h2>
                    <p className="mt-2 text-2xl text-gray-900 poppins-semibold">
                        Pricing plans for teams of&nbsp;all&nbsp;sizes
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-500 text-left poppins-medium ">
                    Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
                    loyalty, and driving sales.
                </p> */}
                    {
                        frequencies?.length > 1 ? <div className="mt-16 flex justify-center">
                            <RadioGroup
                                value={frequency}
                                onChange={setFrequency}
                                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                            >
                                <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                                {frequencies.map((option) => (
                                    <RadioGroup.Option
                                        key={option.value}
                                        value={option}
                                        className={({ checked }) =>
                                            classNames(
                                                checked ? 'bg-sky-600 text-white' : 'text-gray-500',
                                                'cursor-pointer rounded-full px-2.5 py-1'
                                            )
                                        }
                                    >
                                        <span>{option.label}</span>
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                        </div> : null
                    }
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {tiers.map((tier) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.mostPopular ? 'ring-2 ring-sky-600' : 'ring-1 ring-gray-200',
                                    'rounded-3xl p-8 xl:p-10'
                                )}
                            >
                                <div className="flex items-center justify-between gap-x-4">
                                    <h3
                                        id={tier.id}
                                        className={classNames(
                                            tier.mostPopular ? 'text-sky-600' : 'text-gray-900',
                                            'text-lg font-semibold leading-8'
                                        )}
                                    >
                                        {tier.name}
                                    </h3>
                                    {tier.mostPopular ? (
                                        <p className="rounded-full bg-sky-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-sky-600">
                                            Most popular
                                        </p>
                                    ) : null}
                                </div>
                                <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                                {frequency.value === 'yearly' ? <p className="mt-6 text-xl font-bold tracking-tight text-gray-900 block">
                                    <s>{currency}{(tier.price['monthly'] * 12).toLocaleString("en-US")}</s>
                                    <strong className='text-yellow-600 '> -{
                                        ((((tier.price['monthly'] * 12) - tier.price[frequency.value]) / (tier.price['monthly'] * 12)) * 100).toFixed(2)
                                    }%</strong>
                                </p> : null}
                                <p className="flex items-baseline gap-x-1">
                                    <span className="text-4xl font-bold tracking-tight text-gray-900">{currency}{tier.price[frequency.value].toLocaleString("en-US")}</span>
                                    <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
                                </p>
                                <a
                                    href={`https://admin.crmdost.com/plans/${frequency.value}/${tier.planType}`}
                                    aria-describedby={tier.id}
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'bg-sky-600 text-white shadow-sm'
                                            : 'text-sky-600 ring-1 ring-inset ring-sky-200 hover:text-white',
                                        'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 hover:bg-sky-500 hover:ring-sky-300 cursor'
                                    )}
                                >
                                    Buy plan
                                </a>
                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3 text-left">
                                            <CheckIcon className="h-6 w-5 flex-none text-sky-600" aria-hidden="true" />
                                            <span dangerouslySetInnerHTML={{ __html: feature }} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
