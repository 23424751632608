import Footer from '../components/footer';
import Header from '../components/header';

export default () => {

    return <>
        <Header />
        <div className="p-10 px-6 sm:px-0 m-auto max-w-7xl">
            <h1 className='text-2xl mb-5'>Terms Conditions</h1>
            <p>
                These terms and conditions ("Terms") are a legally binding agreement
                between you and CRM Dost regarding your use of our CRM solution and
                associated services. By accessing or using CRM Dost, you agree to
                comply with and be bound by these Terms. If you do not agree with
                these Terms, please do not use our services.
            </p>
            <h2>1. Acceptance of Terms</h2>
            <p>
                By using CRM Dost, you agree to abide by these Terms, our Privacy
                Policy, and any other policies or guidelines made available to you.
                These Terms may be updated from time to time, and it is your
                responsibility to review them regularly. Your continued use of the
                service after any changes constitutes acceptance of the updated
                Terms.
            </p>
            <h2>2. Service Description</h2>
            <p>
                CRM Dost provides a customer relationship management (CRM) solution
                to help businesses manage their customer relationships, sales,
                marketing, and other related activities. The features and
                functionality of the service may change, be updated, or expanded
                upon at any time.
            </p>
            <h2>3. User Accounts</h2>
            <p>
                You may be required to create an account to use CRM Dost. You are
                responsible for maintaining the security of your account
                information, including your username and password. You agree not to
                share your account credentials with others and to promptly notify us
                of any unauthorized access or use of your account.
            </p>
            <h2>4. User Responsibilities</h2>
            <p>
                You agree to use CRM Dost in a manner consistent with all applicable
                laws and regulations. You will not engage in any activity that could
                harm the service, disrupt other users, or violate the rights of
                others. Prohibited activities include, but are not limited to
                hacking, spreading malware, spamming, or engaging in abusive
                behavior.
            </p>
            <h2>5. Payment and Subscription</h2>
            <p>
                If CRM Dost offers paid subscriptions, you agree to pay the fees
                associated with your chosen plan. These fees may be subject to
                change, and you will be notified in advance of any such changes.
                Payment is processed through secure third-party payment processors,
                and your subscription may automatically renew unless you cancel it.
            </p>
            <h2>6. Data Privacy</h2>
            <p>
                Your use of CRM Dost is subject to our Privacy Policy, which
                outlines how we collect, use, and protect your personal and business
                information. Please review our Privacy Policy for more information
                about the data we collect and how it is managed.
            </p>
            <h2>7. Termination</h2>
            <p>
                We reserve the right to terminate or suspend your access to CRM Dost
                for violations of these Terms or for any other reason at our
                discretion. You may also terminate your account at any time by
                following the appropriate process outlined in the service.
            </p>
            <h2>8. Limitation of Liability</h2>
            <p>
                We are not liable for any direct, indirect, incidental, special,
                consequential, or exemplary damages or losses, including but not
                limited to loss of profits, data, goodwill, or business
                opportunities, resulting from your use of CRM Dost. Your use of the
                service is at your own risk, and the service is provided "as is"
                without warranties of any kind.
            </p>
            <h2>9. Contact Information</h2>
            <p>
                If you have any questions about these Terms or need to contact us
                for any reason, please use the following contact information: info@crmdost.com.
            </p>
            <h2>10. Governing Law</h2>
            <p>
                These Terms are governed by and construed in accordance with the
                laws of India. Any disputes arising from the use of
                CRM Dost will be subject to the exclusive jurisdiction of the courts
                located in India.
            </p>
            <p>Last Updated: 24th Oct 2023</p>
        </div>
        <Footer />
    </>
}