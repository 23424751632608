export default ({ id, children, bgImage, bg, space = 0, innerClass = '' }) => {

    return <div id={id} style={{ marginTop: -space }} className={`${bg} relative`}>
        <div className={`${bgImage} absolute z-1`} />
        <div
            style={{ paddingTop: space }}
            className={`
        min-h-screen relative z-2 md:flex  max-w-7xl px-6 mx-auto w-full items-center justify-center
        ${innerClass}
        `}>
            {children}
        </div>
    </div>
}